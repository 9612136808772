import { Box } from "../../components/styled-system/Box";
import { Divider } from "../../components/layout/Divider";
import { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import {
  uploadDatabase,
  uploadSynonyms,
  downloadDatabase,
  downloadSynonyms,
  downloadSearchHistory
} from "../../api/old-vine-registry-api";

const UploadCompleted = (props: { uploadCompleted: boolean, 
                                  uploadSuccess: boolean, 
                                  uploadOption: string }) => (
    <>
      {props.uploadCompleted && (
          <span style={{paddingLeft:'20px', fontSize:"medium", color: props.uploadSuccess ? "black" : "black"}}> 
            Upload Process Completed - please check error log for potential issues
          </span>
      )}
    </>
);

const Loading = (props: { loading: boolean }) => (
  <>
    {props.loading && (
      <Box display="inline-block" height="25px" ml="20px">
        {props.loading && <Spinner animation="border" role="status" />}
      </Box>
    )}
  </>
);



export const AdminPage = () => {
  const [uploadState, setUploadState] = useState({
    uploading: false,
    completed: false,
    success: false,
    option: "",
  });
  const [downloading, setDownloading] = useState(false);
  const [csvFile, setCsvFile] = useState<File>();
  const [error, setError] = useState("");
  const [vineyardValue, setVineyardValue] = useState('');
  const [synonymValue, setSynonymValue] = useState('');

  const handleFileUpload = async (
    file: File | undefined,
    apiCall: (file: File) => Promise<void>,
    option: string
  ) => {
    if (file && validate(file)) {
      setUploadState({ uploading: true, completed: false, success: false, option });
  
      try {
        await apiCall(file);
        setUploadState({ uploading: false, completed: true, success: true, option });
        setCsvFile(undefined);
        if (option === "vineyard") setVineyardValue("");
        if (option === "synonym") setSynonymValue("");
      } catch (error) {
        console.error("Upload failed:", error);
        setUploadState({ uploading: false, completed: true, success: false, option });
      }
    }
  };

  const uploadVineyardTsv = () => handleFileUpload(csvFile, uploadDatabase, "vineyard");
  const uploadSynonymTsv = () => handleFileUpload(csvFile, uploadSynonyms, "synonym");
  
  const validate = (file?: File) => {
    if (!file) {
      setError("TSV file required");
      return false;
    }
    if (!file.name.match(/.*\.[ct|CT][s|S][v|V]/)) {
      setError("File must be of type CSV or TSV");
      return false;
    }
    setError("");
    return true;
  };

  const handleVineyardsChange = (e:any) => {
    setVineyardValue(e.target.value);

    if (e.target.files && e.target.files.length > 0) {
      setCsvFile(e.target.files[0]);
      validate(e.target.files[0]);
    }
  };

  const handleSynonymChange = (e:any) => {
    setSynonymValue(e.target.value);

    if (e.target.files && e.target.files.length > 0) {
      setCsvFile(e.target.files[0]);
      validate(e.target.files[0]);
    }
  };

  const handleDownload = async (downloadFn: () => Promise<void>) => {
    setDownloading(true);
    try {
      await downloadFn();
    } finally {
      setTimeout(() => setDownloading(false), 2000);
    }
  };

  
  const downloadVineyardsTsv = () => handleDownload(downloadDatabase);
  const downloadSynonymTsv = () => handleDownload(downloadSynonyms);
  const downloadSearchHistoryTsv = () => handleDownload(downloadSearchHistory);
  
  return (
    <Box className="submit-layout" maxWidth="1000px">
      <div className="title-large">Administration</div>
      <Box mt="2.5rem">
        <span className="title-small">Database update:</span>
        <br />
        <span className="description-text-center">
          To make updates to the vineyard database, please upload a TSV file here.
        </span>
        <br />
        {error && <span className="input-error">{error}</span>}
        <br />
        <Form.Control
          type="file"
          className="input-text"
          value={vineyardValue}
          onChange={handleVineyardsChange}
        />
        <span className="warning-text">
          WARNING: This is a destructive process - it will overwrite all existing data.
        </span>
        <br/>
        <button
          className="button u-mt-medium"
          onClick={uploadVineyardTsv}
          disabled={uploadState.uploading}
        >
          Upload Vineyards
        </button>
        {uploadState.option === "vineyard" && <>
                <Loading loading={uploadState.uploading} />
                <UploadCompleted 
                      uploadOption={uploadState.option} 
                      uploadCompleted={uploadState.completed} 
                      uploadSuccess={uploadState.success} />
                </>
        }
        <br/>
        <br />
        <br />
        <span className="description-text-center">
          To make updates to the synonym table, please upload a TSV file here.
        </span>
        <Form.Control
          type="file"
          className="input-text"
          value={synonymValue}
          onChange={handleSynonymChange}
        />
        <span className="warning-text">
          WARNING: This is a destructive process - it will overwrite all existing data.
        </span>
        <br/>
        <button
          style={{marginLeft: '5px'}}
          className="button u-mt-medium"
          onClick={uploadSynonymTsv}
          disabled={uploadState.uploading}
        >
          Upload Synonyms
        </button>
        {uploadState.option === "synonym" && <>
                <Loading loading={uploadState.uploading} />
                <UploadCompleted 
                      uploadOption={uploadState.option} 
                      uploadCompleted={uploadState.completed} 
                      uploadSuccess={uploadState.success} />
                </>
        }
        <br/>
        
        <br/><br/>
        <div className="title-large">Downloads</div>
        <span className="description-text-center u-mr-medium">
          Download the current data to .TSV file(s):
        </span><br />
        <button className="button" onClick={downloadVineyardsTsv} disabled={downloading}>
          Download Vineyard Info
        </button><br /><br />
        <button className="button" onClick={downloadSynonymTsv} disabled={downloading}>
          Download Synonyms
        </button><br /><br />
        <button className="button" onClick={downloadSearchHistoryTsv} disabled={downloading}>
          Download Search History
        </button><br />
        <Loading loading={downloading} />
        <Divider />
        <div className="title-large">Other</div>
        <a href="./admin/errors"><span className="description-text-center">List of errors (order by latest)</span></a><br/><br/>
        <a href="./admin/submissions"><span className="description-text-center">List of submissions (order by latest)</span></a><br/><br/>
       
      </Box>

      {/*<Box height="150px" />*/}
    </Box>
  );
};
