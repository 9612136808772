import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { AppHeader } from "../../components/app-header/AppHeader";
import { AppFooter } from "../../components/app-footer/AppFooter";

export const NotFound = () => {
  const navigate = useNavigate();

  const AppLayout = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
  `;

  return <AppLayout>
      <AppHeader/>
      <div className={`submit-layout`}>
        <div className="title-large">404 - Page Not Found</div>
        <p className="description-text u-mt-large">
          These are not the vines you are looking for...<br/><br/>
          Please click on <span className="description-text-link" onClick={() => navigate("/")}>Old Vine Registry</span> to return to the registry
        </p>
      </div>
      <AppFooter/>
    </AppLayout>;
};
