import "./LoginPage.scss";
import * as Yup from "yup";
import styled from "styled-components";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Box } from "../../components/styled-system/Box";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../api/old-vine-registry-api";
import { Paths } from "../../paths";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth/authSlice";
import { AppHeader } from "../../components/app-header/AppHeader";
import { AppFooter } from "../../components/app-footer/AppFooter";

const Loading = (props: { loading: boolean }) => (
  <Box display="flex" justifyContent="center" alignItems="center" height="25px">
    {props.loading && <Spinner animation="border" role="status" />}
  </Box>
);

export const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [authenticating, setAuthenticating] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);

  const AppLayout = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
  `;
  useEffect(() => {}, []);
  
  const login = async (values: { username: string; password: string }) => {
    try {
      setAuthenticating(true);
      setLoginFailed(false);
      const bearerToken = await loginUser(values.username, values.password);
      if (bearerToken) {
        dispatch(authActions.setBearerToken(bearerToken));
        navigate(Paths.admin);
      } else {
        setLoginFailed(true);
      }
    } finally {
      setAuthenticating(false);
    }
  };

  return <AppLayout>
      <AppHeader/>
        <div className={`submit-layout center-horizontal`}>
          <div className="title-large">Login</div>
          <p className="description-text u-mt-large">
            Please log in in order to continue with Old Vine Registry
            administration.
          </p>
          <p className="description-text">* required fields</p>
          <Formik
            initialValues={{ username: "", password: "", admin: "" }}
            validationSchema={Yup.object({
              username: Yup.string().required("User name required"),
              password: Yup.string().required("Password required"),
            })}
            onSubmit={login}
          >
            <Form>
              <Box display="flex" flexDirection="column" maxWidth="600px">
                <label htmlFor="username">
                  <span className="form-label">Your name*</span>
                </label>
                <ErrorMessage
                  name="username"
                  className="input-error"
                  component="span"
                />
                <Field
                  name="username"
                  type="text"
                  autoComplete="off"
                  className="input-text"
                />

                <label htmlFor="password" className="u-mt-medium">
                  <span className="form-label">Your password*</span>
                </label>
                <ErrorMessage
                  name="password"
                  className="input-error"
                  component="span"
                />
                <Field
                  name="password"
                  type="password"
                  autoComplete="off"
                  className="input-text"
                />
                <span>
                  <button
                    type="submit"
                    className="button u-mt-medium"
                    disabled={authenticating}
                  >
                    Login
                  </button>
                </span>
                <Loading loading={authenticating} />
                {loginFailed && (
                  <span className="input-error">
                    Invalid user name or password.
                  </span>
                )}
              </Box>
            </Form>
          </Formik>
        </div>
      <AppFooter/>
  </AppLayout>;
};
