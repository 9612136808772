import "./SubmissionPage.scss";
import { Box } from "../../../components/styled-system/Box";
import Pagination from "react-bootstrap/Pagination";
import { Spinner } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { TableHeader } from "../../../components/table/TableHeader";
import {
  getSubmissions
} from "../../../api/old-vine-registry-api";
import { Submission, SubmissionResultPage} from "../../../api/models";

const pageSize = 50;

const Loading = (props: { loading: boolean }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="25px"
    mt="20px"
  >
    {props.loading && <Spinner animation="border" role="status" />}
  </Box>
);

const TD = ({ children }: { children: React.ReactNode }) => (
  <td>
    <Box height="100%" display="flex" alignItems="center">
      {children}
    </Box>
  </td>
);

function useSubmissionsLoader() {
  const [submissions, setSubmissions] = useState<Submission[]>([]);
  const [totalResults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const loadData = async (
    page = 0
  ) => {
    setLoading(true);
    let submissionsResultsPage: SubmissionResultPage;
    const submissionsQueryBase = {
      order: "desc",
      pageNumber: page,
      pageSize,
    };
    
    submissionsResultsPage = await getSubmissions({
      ...submissionsQueryBase})
    
    setSubmissions(submissionsResultsPage.content || []);
    setFirstLoad(false);
    setTotalResults(submissionsResultsPage.totalElements);
    setTotalPages(submissionsResultsPage.totalPages);
    setCurrentPage(page);
    setLoading(false);
  };

  const nextPage = () => {
    if (currentPage === totalPages - 1) {
      return;
    }
    const next = currentPage + 1;
    setCurrentPage(next);
    loadData(next);
  };

  const prevPage = () => {
    if (currentPage === 0) {
      return;
    }
    const next = currentPage - 1;
    setCurrentPage(next);
    loadData(next);
  };

  const lastPage = () => {
    const last = totalPages - 1;
    setCurrentPage(last);
    loadData(last);
  };

  const firstPage = () => {
    setCurrentPage(0);
    loadData(0);
  };

  //load when page is loaded
  useEffect(() => {
    if (firstLoad) {
      loadData();
      console.log("Submissions Table First Load")
    }
    console.log("Submission Table Component Loaded")
  }, [firstLoad]);

  return {
    loading,
    data: submissions,
    totalResults,
    totalPages,
    currentPage,
    loadData,
    firstPage,
    prevPage,
    nextPage,
    lastPage,
  };
}

export const SubmissionsTable = () => {
  const {
    loading,
    data: tableData,
    totalPages,
    currentPage,
    nextPage,
    lastPage,
    prevPage,
    firstPage,
  } = useSubmissionsLoader();

  useEffect(() => {
    // Set the title when the component mounts
    document.title = 'Old Vine Errors';
    
    // Clean up the title when the component unmounts
    return () => {
      document.title = 'Old Vine Registry';
    };
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    
    <div className="content-panel">
      <span className="title-large">
        {loading ? "loading..." : "Submission List (sorted latest desc)"}
      </span>

      <Loading loading={loading} />
      {tableData.length > 0 && (
        <>
          <Table
            hover
            size="sm"
            className="vineyard-table input-text u-mt-large"
          >
            <thead>
              <tr>
                <TableHeader field="id">
                  Submission Id
                </TableHeader>
                <TableHeader field="createdDate">
                  Date
                </TableHeader>
                <TableHeader field="customMessage">
                  Submitter Email
                </TableHeader>
                <TableHeader field="exception">
                  Submitter Name
                </TableHeader>
                <TableHeader field="exception">
                  Submitter Vineyard
                </TableHeader>
              </tr>
            </thead>
            <tbody>
              {tableData?.map((row, i) => (
                <tr key={i}>
                  <TD>
                    <span className="search-link">{row.id}</span>
                  </TD>
                  <TD>{row.createdDate.toString()}</TD>
                  <TD>
                    <span className="break-text">{row.submitterEmail}</span>
                  </TD>
                  <TD>
                    <span className="break-text">{row.submitterName}</span>
                  </TD>
                  <TD>
                    <span className="break-text">{row.vineyardName}</span>
                  </TD>
                </tr>
              ))}
            </tbody>
          </Table>

          {totalPages > 1 && (
            <Box display="flex" alignItems="center" mt="20px">
              <span className="description-text">
                Page {currentPage + 1} of {totalPages}
              </span>
              <Pagination className="u-ml-medium">
                <Pagination.First
                  onClick={() => firstPage()}
                  disabled={currentPage === 0}
                />
                <Pagination.Prev
                  onClick={() => prevPage()}
                  disabled={currentPage === 0}
                />
                <Pagination.Next
                  onClick={() => nextPage()}
                  disabled={currentPage === totalPages - 1}
                />
                <Pagination.Last
                  onClick={() => lastPage()}
                  disabled={currentPage === totalPages - 1}
                />
              </Pagination>
            </Box>
          )}
        </>
      )}
    </div>
  );
};
