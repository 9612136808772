import "./SearchOptions.scss";
import { Box } from "../styled-system/Box";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getCountries, getVarieties } from "../../api/old-vine-registry-api";

export interface SearchOptionProps {
  searchCountry: (country: string) => void;
  searchAgeRange: (ageRange: string) => void;
  searchVariety: (variety: string) => void;
  className?: string;
}

function useCountries(): string[] {
  const [countries, setCountries] = useState<string[]>([]);
  useEffect(() => {
    getCountries().then((countries_) => setCountries(countries_));
  }, []);

  return countries;
}

function useVarieties(): string[] {
  const [varieties, setVarieties] = useState<string[]>([]);
  useEffect(() => {
    getVarieties().then((varieties_) => setVarieties(varieties_));
  }, []);

  return varieties;
}

export const SearchOptions = ({
  searchCountry,
  searchAgeRange,
  searchVariety,
  className,
}: SearchOptionProps) => {
  const countries = useCountries();
  const varieties = useVarieties();
  const [filterType, setFilterType] = useState(""); // Add this state
  const [country, setCountry] = useState("");
  const [variety, setVariety] = useState("");
  const [ageRange, setAgeRange] = useState("");

  return (
    <Box className={`description-text search-box ${className || ""}`}>
      <Box>
        <span className="select-label u-mr-small">
          View by:
        </span>
        <Box display="flex" alignItems="center">
          <Box className="search-combo" display="inline-block">
            <Form.Select
              className="input-text input-height"
              onChange={(e) => setFilterType(e.target.value)}
            >
              <option className="search-text" value="">Select filter type</option>
              <option value="country">Country</option>
              <option value="variety">Variety</option>
              <option value="age">Age</option>
            </Form.Select>
          </Box>
        </Box>
      </Box>
      {filterType === 'country' && (
        <Box>
          <span className="select-label u-mr-small">
            Select country:
          </span>
          <Box display="flex" alignItems="center">
            <Box className="search-combo" display="inline-block">
              <Form.Select
                className="input-text input-height"
                onChange={(e) => setCountry(e.target.value)}
              >
                <option value="">Select country</option>
                {countries.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).map((country) => (
                  <option value={country} key={country}>
                    {country}
                  </option>
                ))}
              </Form.Select>
            </Box>
            <button
              className="button u-ml-small search-command"
              disabled={!country}
              onClick={() => searchCountry(country)}
            >
              Go
            </button>
          </Box>
        </Box>
      )}
      {filterType === 'variety' && (
        <Box>
          <span className="select-label u-mr-small">
            Select variety:
          </span>
          <Box display="flex" alignItems="center">
            <Box className="search-combo" display="inline-block">
              <Form.Select
                className="input-text input-height"
                onChange={(e) => setVariety(e.target.value)}
              >
                <option value="">Select variety</option>
                {varieties.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).map((variety) => (
                  <option value={variety} key={variety}>
                    {variety}
                  </option>
                ))}
              </Form.Select>
            </Box>
            <button
              className="button u-ml-small search-command"
              disabled={!variety}
              onClick={() => searchVariety(variety)}
            >
              Go
            </button>
          </Box>
        </Box>
      )}
      {filterType === 'age' && (
        <Box>
          <span className="select-label u-mr-small">
            Select age range:
          </span>
          <Box className="search-item" display="flex" alignItems="center">
            <Box className="search-combo" display="inline-block">
              <Form.Select
                className="input-text input-height"
                onChange={(e) => setAgeRange(e.target.value)}
              >
                <option value="">Select age range</option>
                <option value="35-54">35-54 years</option>
                <option value="55-74">55-74 years</option>
                <option value="75-94">75-94 years</option>
                <option value="75-114">95-114 years</option>
                <option value="115-134">115-134 years</option>
                <option value="135-154">135-154 years</option>
                <option value="155+">More than 155 years</option>
              </Form.Select>
            </Box>
            <button
              className="button u-ml-small search-command"
              disabled={!ageRange}
              onClick={() => searchAgeRange(ageRange)}
            >
              Go
            </button>
          </Box>
        </Box>
      )}
    </Box>

  );
};
